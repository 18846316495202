/* You can add global styles to this file, and also import other style files */

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/assets/fonts/Metropolis/Metropolis-Light.otf") format("opentype");
}

@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/Metropolis/Metropolis-Regular.otf") format("opentype");
}

@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/assets/fonts/Metropolis/Metropolis-Medium.otf") format("opentype");
}

@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/assets/fonts/Metropolis/Metropolis-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/assets/fonts/Metropolis/Metropolis-Bold.otf") format("opentype");
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/assets/fonts/Raleway/Raleway-Light.otf") format("truetype");
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/Raleway/Raleway-Regular.otf") format("truetype");
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/assets/fonts/Raleway/Raleway-Medium.otf") format("truetype");
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/assets/fonts/Raleway/Raleway-SemiBold.otf") format("truetype");
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/assets/fonts/Raleway/Raleway-Bold.otf") format("truetype");
}

body,
button,
input,
select,
textarea,
a,
p {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    color: #1D1D1B;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Metropolis';
    color: #000;
    line-height: 1.25 !important;
}

.height-without-bar {
  height: calc(100% - 4rem)
}

.accordion-body {
  padding: 0 !important;
}

.swiper-wrapper {
  min-height: 100%;
}

.swiper-slide {
  height: 100% !important;
}

.swiper-pagination-bullet {
  flex: 1;
  color: gray;
  opacity: 0.3;
  height: 5px;
  border-radius: 2em;
}

.swiper-pagination {
  flex-direction: row;
  display: flex;
  align-content: space-between;
  top: 0.2em;
  height: min-content;
}

/* Button */

/* Custom */
.color-link, .color-link:hover {
  color: #0073E6;
}

.border-solid-black {
  border: 1px solid #000;
}

.custom-tab-active p {
  color: black;
  font-weight: bold
}

/* Map Popup */
.height-without-bar .leaflet-popup-content-wrapper .leaflet-popup-content { margin: 8px 8px 8px 8px !important; }

/* ================================== CUSTOM CLASSES BASED ON TERRITORY ============================================*/


/* Custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

@media (max-width: 639px) {
  ::-webkit-scrollbar {
    width: 16px;
  }
}
/* End of Custom scrollbar */
